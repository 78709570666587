import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {getToken} from "../../util/AuthHelper";

export default function AnonymousOnly({component: Component}) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const token = getToken();

    useEffect(() => {
        if (token) {
            navigate("/home");
        } else {
            setLoading(false);  // Stop loading once the token is confirmed
        }
    }, [token, navigate]);

    if (loading) {
        return null;  // Or you can return a loader/spinner here
    }

    return (
        <Component/>
    )
}