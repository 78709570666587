import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import LandingPage from "./pages/LandingPage/LandingPage";
import AnonymousOnly from "./component/auth/AnonymousOnly";
import AboutUsPage from "./pages/AboutUs/AboutUsPage";

function App() {
  return (
    <div className="App" style={{ height: '100vh', width: '100vw' }}>
        <Router>
            <Routes>
                <Route exact path="/" element={<AnonymousOnly component={AboutUsPage}/>}/>
                <Route exact path="/aboutus" element={<AnonymousOnly component={AboutUsPage}/>}/>
            </Routes>
        </Router>
    </div>
  );
}

export default App;
