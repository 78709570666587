import React from "react";
import MailchimpForm from "./MailChimpForm";

export default function AboutUsDescription() {
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            paddingTop: "calc(10px + 2vw)",
            paddingBottom: "calc(10px + 2vw)",
            backgroundColor: "#f2d073"
        }}>
            <div style={{ maxWidth: "90%", margin: "0 auto" }}>
                <div style={{
                    paddingTop: "calc(5% + 1vw)",
                    paddingBottom: "calc(20px + 2vw)"
                }}>
                    <p style={{
                        color: "#b54778",
                        fontSize: "calc(10px + 1.5vw)",
                        display: "block",
                        textAlign: "center",
                        maxWidth: "800px",
                        margin: "0 auto",
                        overflowWrap: "break-word",
                    }}>
                        Nunca más estarás solo en tu camino emprendedor
                    </p>
                    <p style={{
                        fontSize: "calc(10px + 1.2vw)",
                        display: "block",
                        marginTop: "calc(5px + 1vw)",
                        maxWidth: "800px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        textAlign: "center",
                        overflowWrap: "break-word",
                    }}>
                        DAC está aquí para guiarte, crecer contigo y ayudarte a tomar las mejores decisiones.
                    </p>
                </div>
                <div style={{
                    paddingTop: "calc(15px + 2vw)",
                    paddingBottom: "calc(15px + 2vw)",
                    maxWidth: "800px",
                    margin: "0 auto",
                }}>
                    <p style={{
                        fontSize: "calc(10px + 1vw)",
                        lineHeight: "1.5",
                        marginBottom: "calc(10px + 1vw)",
                        maxWidth: "800px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        textAlign: "center",
                        overflowWrap: "break-word",
                    }}>
                        En <strong>DAC</strong>, creemos en el poder de la innovación y en la importancia de brindar
                        apoyo continuo a quienes se atreven a emprender. Somos una startup joven, impulsada por la pasión de sus fundadores que combinan conocimiento empresarial y la ingeniería de software para transformar la forma en que los emprendedores reciben asesoramiento.
                    </p>
                    <p style={{
                        fontSize: "calc(10px + 1vw)",
                        lineHeight: "1.5",
                        maxWidth: "800px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        textAlign: "center",
                        overflowWrap: "break-word",
                    }}>
                        Nuestro producto está en <strong>BETA/early access</strong>, porque valoramos tu opinión y
                        queremos crecer contigo. DAC nace como una plataforma en constante evolución, diseñada para adaptarse a tus necesidades y resolver tus dudas de manera rápida y eficiente.
                    </p>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: "calc(15px + 2vw)"
                }}>
                    <p style={{
                        fontSize: "calc(10px + 1vw)",
                        lineHeight: "1.5",
                        color: "#b54778",
                        textAlign: "center",
                        maxWidth: "800px",
                        marginBottom: "calc(10px + 1vw)",
                        overflowWrap: "break-word",
                    }}>
                        ¡Suscríbete a nuestra newsletter! Se el primero en tener acceso a DAC
                    </p>
                    <MailchimpForm/>
                </div>
                <div>
                    <p style={{
                        fontSize: "calc(10px + 1vw)",
                        lineHeight: "1.5",
                        maxWidth: "800px",
                        margin: "0 auto",
                        textAlign: "center",
                        overflowWrap: "break-word",
                        paddingTop: "calc(10px + 2vw)"
                    }}>
                        Sigues curioso? No dudes en escribirnos!
                    </p>
                    <p style={{
                        fontSize: "calc(10px + 1.5vw)",
                        color: "#b54778",
                        maxWidth: "800px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "calc(10px + 1vw)",
                        textAlign: "center",
                        overflowWrap: "break-word",
                    }}>
                        info@dac.es
                    </p>
                </div>
            </div>
        </div>
    )
}
