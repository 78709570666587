import React from 'react'
import dacIcon from '../media/icons/icono_dac.png';
import {Link} from "react-router-dom";
//PURPLE b44788
//YELLOW f2d073
export default function NavBar() {
    return (
        <div>
            <nav className="navbar navbar-expand-lg" style={{backgroundColor: 'white'}}>
                <div className="container-fluid">
                    <Link className="d-flex align-items-center" to={`/`} style={{textDecoration: 'none'}}>
                        <img className="navbar-img-top" src={dacIcon} width="45px" height="45"
                             style={{borderRadius: '8px'}}/>
                        <div className="navbar-brand"
                             style={{color: "#b44788", fontSize: "27px", paddingLeft: "5px"}}>DAC
                        </div>
                    </Link>

                    <div className="d-flex align-items-center ms-5" style={{color: "#b44788", fontSize: "20px"}}>
                        <Link className="me-4" to={'/aboutus'} style={{color: "#b44788",textDecoration: 'none'}}>
                            Que es DAC?
                        </Link>
                    </div>
                    {/*<div className="d-flex ms-auto" style={{marginRight: "1%"}}>*/}
                    {/*    <Link className="btn me-2" to={`/login`}*/}
                    {/*          style={{backgroundColor: "white", color: "black", border: "1px solid gray"}}>Login</Link>*/}
                    {/*    <Link to={`/signup`} className="btn"*/}
                    {/*          style={{backgroundColor: "#B44778", color: "white", border: "none"}}>Registrate*/}
                    {/*        gratis</Link>*/}
                    {/*</div>*/}
                </div>
            </nav>
        </div>
    )
}