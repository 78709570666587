import React, { useEffect } from "react";

export default function MailchimpForm() {
    useEffect(() => {
        // Dynamically load the Mailchimp validation script
        const script = document.createElement("script");
        script.src = "//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js";
        script.async = true;
        document.body.appendChild(script);

        // Clean up the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div id="mc_embed_shell" style={{ width: "100%",padding: "10px", boxSizing: "border-box" }}>
            <link
                href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
                rel="stylesheet"
                type="text/css"
            />
            <style type="text/css">
                {`
          #mc_embed_signup {
            background: #fff;
            font: 14px Helvetica, Arial, sans-serif;
            width: 100%;
            max-width: 600px;
            margin: 0 auto;
            border-radius: 12px;
            padding: 20px;
            box-sizing: border-box;
          }
          #mc_embed_signup h2 {
            font-size: calc(16px + 1vw);
            text-align: center;
            margin-bottom: 20px;
          }
          #mc_embed_signup input[type="email"] {
            width: 100%;
            padding: 10px;
            font-size: calc(14px + 0.5vw);
            margin-bottom: 10px;
            border: 1px solid #ddd;
            border-radius: 6px;
            box-sizing: border-box;
          }
          #mc_embed_signup .button {
            background-color: #b54778;
            color: white;
            font-size: calc(14px + 0.5vw);
            paddingBottom: 10px;
            border: none;
            border-radius: 6px;
            cursor: pointer;
            width: 100%;
            max-width: 300px;
            margin: 0 auto;
            display: block;
          }
          #mc_embed_signup .response {
            font-size: calc(12px + 0.2vw);
            color: #b54778;
            margin-top: 10px;
            text-align: center;
          }
        `}
            </style>
            <div id="mc_embed_signup">
                <form
                    action="https://dac.us19.list-manage.com/subscribe/post?u=c4b94269387c12673e70ab056&amp;id=c35b950004&amp;f_id=00f9c0e2f0"
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    className="validate"
                    target="_blank"
                >
                    <div id="mc_embed_signup_scroll">
                        <h2>Solicita acceso a DAC</h2>
                        <div className="mc-field-group">
                            <label htmlFor="mce-EMAIL">
                                Dirección de correo electrónico <span className="asterisk">*</span>
                            </label>
                            <input
                                type="email"
                                name="EMAIL"
                                className="required email"
                                id="mce-EMAIL"
                                placeholder="Escribe tu correo electrónico"
                                required
                            />
                        </div>
                        <div id="mce-responses" className="clear">
                            <div
                                className="response"
                                id="mce-error-response"
                                style={{ display: "none" }}
                            ></div>
                            <div
                                className="response"
                                id="mce-success-response"
                                style={{ display: "none" }}
                            ></div>
                        </div>
                        <div
                            aria-hidden="true"
                            style={{ position: "absolute", left: "-5000px" }}
                        >
                            <input
                                type="text"
                                name="b_c4b94269387c12673e70ab056_c35b950004"
                                tabIndex="-1"
                            />
                        </div>
                        <div className="clear">
                            <input
                                type="submit"
                                name="subscribe"
                                id="mc-embedded-subscribe"
                                className="button"
                                value="Suscríbete"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
