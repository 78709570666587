import NavBar from "../../component/NavBar";
import React from "react";
import AboutUsDescription from "./components/AboutUsDescription";

export default function AboutUsPage() {
    return (
        <div>
            <NavBar/>
            <AboutUsDescription/>
        </div>
    )
}